import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

const TopicCard = ({ Name, Image, Icon, strapiId, margin='', ...props }) => {
  // card hover classes
  const hoverCard = 'border-4 border-transparent hover:-my-2 hover:-mx-4 hover:h-44 hover:w-84 hover:border-white hover:border-opacity-30 hover:shadow-md transition';

  return (
    <Link to={`/topics/${strapiId}`} className="cursor-pointer" {...props}>
      <div className={`${margin}`}>
        <div
            className={`rounded-lg bg-gray-200  p-4 h-40 w-76 ${hoverCard} flex flex-col justify-end items-start bg-cover bg-center group`}
            style={{
              ...(Image && {backgroundImage: `url(${Image.publicURL})`}),
            }}
          >
          
          <img className ="w-10 h-10" src={Icon.publicURL} alt={Name}/>
          <h3 className="font-bold font-heading text-black uppercase text-lg mt-3 leading-none">
            {Name}
          </h3>
        </div>
      </div>
    </Link>
  )
}

TopicCard.propTypes = {
  Name: PropTypes.string,
  Image: PropTypes.object,
  strapiId: PropTypes.number,
  margin: PropTypes.string,
}

TopicCard.defaultProps = {
  Name: null,
  Image: null,
  strapiId: null,
  margin: null,
}

export default TopicCard;