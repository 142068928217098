import React from 'react';
import Brand from '../Brand/Brand';
import AppButton from '../AppButton/AppButton';

import winter from './winter.svg';
import logo from './ncsa_logo_new.png';

const Jumbotron = ({...props}) => {
  const checkmark = <div className="w-4"><svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M15.3213 0L14.4773 0.850281C11.5431 3.84514 8.8708 6.79998 6.01903 9.75004L2.41135 6.81183L1.48391 6.05603L0 7.94554L5.37916 12.3292L6.21386 13L6.96508 12.2348C10.1555 8.97785 13.0236 5.76658 16.1653 2.56046L17 1.70071L15.3213 0Z" fill="#EC1C24"/>
  </svg></div>
  
  return (
    <div className="p-4 flex flex-col lg:flex-row space-y-6 lg:space-y-0 items-center justify-between rounded-lg bg-cover bg-center"
      style={{backgroundImage: `url(${winter})`}}
    >
      <div className='w-full text-center'>
        <div className='flex justify-center'>
          <Brand />
        </div>
        <h1 className='uppercase font-extrabold font-heading text-white text-4xl'>Connections</h1>
      </div>
      <div className="p-8 pb-7 h-full max-w-md flex flex-col justify-between space-y-5 rounded-lg bg-white">
        <p className='font-bold text-xl text-gray-800'>
        Welcome to Connections!  An App designed for Alberta youth aged 14-26 who are transitioning to adulthood from government care.
        </p>
        <ul className="text-base font-light text-gray-600">
          <li className='flex flex-row items-baseline mb-2 space-x-3'>{checkmark}<p>Articles, How-To Comics and Tips</p></li>
          <li className='flex flex-row items-baseline mb-2 space-x-3'>{checkmark}<p>Quick access to Supports and Mental Health help</p></li>
          <li className='flex flex-row items-baseline mb-2 space-x-3'>{checkmark}<p>Content and messaging guided by Indigenous Elders and NCSA Program Staff</p></li>
          <li className='flex flex-row items-baseline space-x-3'>{checkmark}<p>Developed in partnership with the Alberta Ministry of Children’s Services</p></li>
        </ul>
        <div className="flex flex-col items-center">
          <p className="text-base font-light text-gray-600 mb-2">App Created and Managed by</p>
          <img className="w-14" alt="Native counselling services of alberta, est 1970" src={logo} />
        </div>
        <div className='flex flex-col sm:flex-row justify-around items-center space-y-4 sm:space-y-0 sm:space-x-4'>
          <AppButton type='ios' cta='Get the app' color='black' size='large' link='https://apps.apple.com/app/id1560206793' />
          <AppButton type='android' cta='Get the app' color='black' size='large' link='https://play.google.com/store/apps/details?id=com.ncsa.ncsa' />
        </div>
      </div>
    </div>
  )
};

export default Jumbotron;