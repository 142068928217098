import React from 'react';
import { Helmet } from 'react-helmet';

import Container from "components/elements/Container/Container";
import Jumbotron from 'components/elements/Jumbotron/Jumbotron';
import TopicSlide from 'components/elements/TopicSlide/TopicSlide';
import ResourceSlide from 'components/elements/ResourceSlide/ResourceSlide';
import './IndexScreen.css';


const IndexScreen = ({data}) => {

  return (
    <>
      <Helmet
        title="Connections"
        />
      <Container >
        <Jumbotron />
      </Container>
      <div className='padded-container mt-14 mb-16'>
        <TopicSlide
          title='Featured topics'
          topics={data.allStrapiTopic.edges}
          extraMargin={true}
        />
      </div>
      <div className='padded-container'>
        <ResourceSlide
          title='Featured resources'
          resources={data.allStrapiResource.edges}
          extraMargin={true}
        />
      </div>
      </>
  );
};

export default IndexScreen;
