import React, { Fragment } from 'react';
import { graphql } from "gatsby";

import IndexScreen from '../components/screens/IndexScreen/IndexScreen';

// In theory we could pass in props that we'd fetched via Gatsby's GraphQL
export default ({data}) => (
  <Fragment>
    <IndexScreen data={data}/>
  </Fragment>
);

export const query = graphql`
  query FeaturedItems {
    allStrapiResource(filter: {feature: {eq: true}}) {
      edges {
        node {
          strapiId
          name
          topic {
            Name
          }
          time_description
          type
          file {
            publicURL
            childImageSharp {
              fluid(quality: 70, maxWidth: 1240) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image_story_thumbnail {
            childImageSharp {
              fluid(quality: 70, maxWidth: 1240) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    },
    allStrapiTopic(filter: {featured: {eq: true}}) {
      edges {
        node {
          strapiId
          Name
          Image {
            publicURL
          }
          Icon {
            publicURL
          }
        }
      }
    }
  }
`;
